// components
import { PrintPanel } from "components/PrintPanel/PrintPanel";

// styled-components
import {
  ControlBoardListCss,
  ControlBoardListItemCss,
} from "./ControlBoard.styled";

export const ControlBoard = () => {
  return (
    <ControlBoardListCss className="controlBoard">
      <ControlBoardListItemCss>
        <PrintPanel />
      </ControlBoardListItemCss>

      <ControlBoardListItemCss>
        {/* Add other panel component */}
      </ControlBoardListItemCss>
    </ControlBoardListCss>
  );
};
