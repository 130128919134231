import styled from "@emotion/styled";
import { Card } from "@mui/material";
import { Typography } from "@mui/material";
import { FormLabel } from "@mui/material";
import { Button } from "@mui/material";
import { Box } from "@mui/material";
import { FormControl } from "@mui/material";
import { Stack } from "@mui/material";
import { TextField } from "@mui/material";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { login } from "services/apiService";
import { toast } from "react-toastify";
import { toastOptions } from "../services/toast-options";
import { useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";

const LoginFormCard = styled(Card)(({ theme }) => ({
  maxWidth: "450px",
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: 4,
  gap: 2,
  margin: "auto",
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
}));

const LoginContainer = styled(Stack)(({ theme }) => ({
  padding: 20,
  marginTop: "10vh",
  maxWidth: "500px",
  marginLeft: "auto",
  marginRight: "auto",
  "&::before": {
    content: '""',
    display: "block",
    position: "absolute",
    zIndex: -1,
    inset: 0,
    backgroundImage:
      "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
    backgroundRepeat: "no-repeat",
  },
}));

export const Login = () => {
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      const result = await login(data.get("email"), data.get("password"));
      if (result.token !== undefined) {
        localStorage.setItem("token", result.token);
      }
      if (localStorage.getItem("invitation_token") !== null) {
        navigate("/invite/accept");
      } else {
        navigate("/");
      }
    } catch (error) {
      toast.error(
        "Something went wrong. Please try again later.",
        toastOptions
      );
    }
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>Appraisal Photo Report | Login</title>
      </Helmet>

      <LoginContainer>
        <LoginFormCard direction="column">
          <Typography
            component="h1"
            variant="h4"
            sx={{
              width: "100%",
              marginTop: "5vh",
              fontSize: "clamp(2rem, 10vw, 2.15rem)",
            }}
          >
            Login
          </Typography>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "90%",
              margin: "auto",
              gap: 2,
              paddingRight: "10px;",
              marginBottom: "2vh",
            }}
            onSubmit={handleSubmit}
          >
            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <TextField
                id="email"
                name="email"
                type="email"
                variant="outlined"
                required={true}
                size="small"
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="password">Password</FormLabel>
              <TextField
                id="password"
                name="password"
                type="password"
                variant="outlined"
                required={true}
                size="small"
              />
            </FormControl>
            <Button type="submit" fullWidth variant="contained">
              Login
            </Button>
          </Box>
          {localStorage.getItem("invitation_token") != null && (
            <>
              <Divider>or</Divider>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  width: "90%",
                  margin: "auto",
                  marginBottom: "5vh",
                  marginTop: "2vh",
                }}
              >
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => navigate("/register")}
                >
                  Register
                </Button>
              </Box>
            </>
          )}
        </LoginFormCard>
      </LoginContainer>
    </HelmetProvider>
  );
};
