// outer sources
import { useState } from "react";
import { isEmpty } from "lodash";
import ScrollToTop from "react-scroll-to-top";

// components
import { Dropzone } from "components/Dropzone";
import { EditTextField } from "../EditTextField/EditTextField";
import { GridFolders } from "components/GridFolders";
import { ControlBoard } from "components/ControlBoard";

// mui icons
import { IconButton } from "@mui/material";
import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop";

export const GridList = () => {
  const [files, setFiles] = useState([]);
  const [toggleChange, setToggleChange] = useState(false);

  /**
   * Set 3rd parameter as indexBasis for all folders.
   *
   * @param {*} folders
   * @returns
   */
  const prepareFolders = (folders) => {
    let previousImagesCount = 0;
    for (let i = 0; i < folders.length; i++) {
      folders[i][2] = previousImagesCount;
      previousImagesCount += folders[i][1].length;
    }

    return folders;
  };

  /**
   * Unified method to set new values / update existing values
   *
   * @param {*} data
   */
  const onUpdate = (data) => {
    if (!isEmpty(data)) {
      setFiles(prepareFolders(data));
      // useState does not recognize changes in objects. This is a quick solution to re-render
      setToggleChange(!toggleChange);
    }
  };

  return (
    <section className="container">
      {!files.length > 0 ? <Dropzone onUpload={onUpdate} /> : null}

      {files.length > 0 ? <ControlBoard /> : null}

      {files.length > 0 ? (
        <EditTextField
          defaultText={"Додаток до Звіту. Фотозвіт оцінюваного майна"}
          addIcon={false}
          style={{
            width: "100%",
            marginBottom: "30px",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        />
      ) : null}

      <GridFolders folders={files} onUpdate={onUpdate} />

      <ScrollToTop
        styles={{ display: "none" }}
        smooth
        top={"1000"}
        component={
          <IconButton color="primary" size="large">
            <VerticalAlignTopIcon fontSize="large" />
          </IconButton>
        }
      />
    </section>
  );
};
