import {
    BrowserRouter as Router,
    Route,
    Routes
} from 'react-router-dom';

import { Home } from '../pages/Home/Home';
import { Login } from '../pages/Login';
import ProtectedRoute from './ProtectedRoute';
import { Invite } from '../pages/Invite';
import { Register } from '../pages/Register';
import { AcceptInvite } from 'pages/AcceptInvite';

export const PageRouter = () => {
    return (
        <Router>
            <Routes>
                <Route exact path="/login" element={<Login />} />
                <Route path="/invite/:token" element={<Invite />} />
                <Route exact path="/register" element={<Register />} />

                <Route element={<ProtectedRoute />}>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/invite/accept" element={<AcceptInvite />} />
                </Route>
            </Routes>
        </Router>
    );
};