import { useEffect } from "react";
import { Navigate, useParams } from "react-router";

export const Invite = () => {
    let { token } = useParams();

    useEffect(() => {
        localStorage.setItem('invitation_token', token);
        // Logout user if he was previously logged in
        localStorage.removeItem('token');
    }, [token]);

    return (<Navigate to='/login' />);
};