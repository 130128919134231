import { Helmet, HelmetProvider } from "react-helmet-async";

// components
import { GridList } from "../../components/GridList";

export const Home = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Appraisal Photo Report | Home</title>
      </Helmet>

      <GridList />
    </HelmetProvider>
  );
};
