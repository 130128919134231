
import { Navigate, Outlet } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const ProtectedRoute = (props) => {
    const token = localStorage.getItem('token');
    
    if (!token) {
        return <Navigate to='/login' />;
    }

    const data = jwtDecode(token);
    let currentTimestamp = new Date().getTime() / 1000;
    if (data.exp < currentTimestamp) {
        return <Navigate to='/login' />;
    }

    return <Outlet {...props} />;
};

export default ProtectedRoute;