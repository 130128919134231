import { Box, Card, Stack, styled, Typography, Button } from "@mui/material";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { HelmetProvider } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { acceptInvite } from "services/apiService";
import { toast } from "react-toastify";
import { toastOptions } from "../services/toast-options";

const Container = styled(Stack)(({ theme }) => ({
  padding: 20,
  marginTop: "10vh",
  maxWidth: "500px",
  marginLeft: "auto",
  marginRight: "auto",
  "&::before": {
    content: '""',
    display: "block",
    position: "absolute",
    zIndex: -1,
    inset: 0,
    backgroundImage:
      "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
    backgroundRepeat: "no-repeat",
  },
}));

const InvitationCard = styled(Card)(({ theme }) => ({
  maxWidth: "450px",
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: 4,
  gap: 2,
  margin: "auto",
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
}));

export const AcceptInvite = () => {
  const [organizationName, setOrganizationName] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    try {
      const token = localStorage.getItem("invitation_token");
      const decoded = jwtDecode(token);
      // TODO: Add expiration validation
      setOrganizationName(decoded.organizationName);
    } catch (error) {
      navigate("/");
    }
  }, [navigate]);

  const handleAccept = async () => {
    try {
      const result = await acceptInvite();
      if (result.error === undefined) {
        toast.success("Invitation was accepted.", toastOptions);
        handleDecline();
      }
    } catch (error) {
      toast.error(
        "Something went wrong. Please try again later.",
        toastOptions
      );
    }
  };

  const handleDecline = () => {
    localStorage.removeItem("invitation_token");
    navigate("/");
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>Appraisal Photo Report | Accept Invitation</title>
      </Helmet>
      <Container>
        <InvitationCard>
          <Typography
            component="h1"
            variant="h2"
            sx={{
              width: "100%",
              marginTop: "5vh",
              fontSize: "clamp(2rem, 10vw, 2.15rem)",
            }}
          >
            Administrator of <strong>{organizationName}</strong> want you to
            join their organization.
          </Typography>
          <Box
            sx={{
              display: "flex",
              // flexDirection: "row",
              width: "90%",
              margin: "auto",
              gap: 2,
              paddingRight: "10px;",
              marginBottom: "2vh",
            }}
          >
            <Button
              type="button"
              variant="contained"
              fullWidth
              color="success"
              onClick={handleAccept}
            >
              Accept
            </Button>
            <Button
              type="button"
              variant="outlined"
              fullWidth
              color="error"
              onClick={handleDecline}
            >
              Decline
            </Button>
          </Box>
        </InvitationCard>
      </Container>
    </HelmetProvider>
  );
};
