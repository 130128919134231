import { ToastContainer } from "react-toastify";

// styles
import "../App.css";
import { PageRouter } from "../router/PageRouter";

export const App = () => {
  return (
    <div className="App">
      <PageRouter />

      <ToastContainer />
    </div>
  );
};
