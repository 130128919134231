import { getDirectoryName } from "./getDirectoryName";

export const getFilesDataForStorage = (filesData) => {
  let filesDataArray = {};
  // eslint-disable-next-line no-unused-vars
  for (const [key, value] of Object.entries(filesData)) {
    if (filesDataArray[getDirectoryName(value.path)] === undefined) {
      filesDataArray[getDirectoryName(value.path)] = [];
    }
    Object.assign(value, {
      preview: URL.createObjectURL(value),
    });
    filesDataArray[getDirectoryName(value.path)].push(value);
  }

  let folders = [];
  Object.entries(filesDataArray).forEach(([folderName, images]) => {
    folders.push([folderName, images]);
  });

  return folders;
};
