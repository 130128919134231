import { useDropzone } from "react-dropzone";

// styled-components
import { DropzoneBoxCss } from "./Dropzone.styled";

// mui icons
import FolderOpenIcon from "@mui/icons-material/FolderOpen";

// utils
import { getFilesDataForStorage } from "../../utils/getFilesDataForStorage";

export const Dropzone = ({ onUpload }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      let files = getFilesDataForStorage(acceptedFiles);
      files.forEach((file) => URL.revokeObjectURL(file.preview));
      sessionStorage.setItem("AcceptedFiles", JSON.stringify(files));
      onUpload(files);
    },
  });

  return (
    <DropzoneBoxCss {...getRootProps({ className: "dropzone" })}>
      <input {...getInputProps()} />
      <p>Drag 'n' drop photo archive folder</p>
      <FolderOpenIcon sx={{ marginLeft: "10px" }} />
    </DropzoneBoxCss>
  );
};
