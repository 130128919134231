import {
  Box,
  Card,
  FormControl,
  FormLabel,
  Stack,
  styled,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { HelmetProvider } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { register } from "services/apiService";
import { toast } from "react-toastify";
import { toastOptions } from "../services/toast-options";

const RegistrationContainer = styled(Stack)(({ theme }) => ({
  padding: 20,
  marginTop: "10vh",
  maxWidth: "500px",
  marginLeft: "auto",
  marginRight: "auto",
  "&::before": {
    content: '""',
    display: "block",
    position: "absolute",
    zIndex: -1,
    inset: 0,
    backgroundImage:
      "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
    backgroundRepeat: "no-repeat",
  },
}));

const RegistrationForm = styled(Card)(({ theme }) => ({
  maxWidth: "450px",
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: 4,
  gap: 2,
  margin: "auto",
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
}));

export const Register = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(name, password);

    try {
      const result = await register(name, password);
      if (result.token !== undefined) {
        localStorage.setItem("token", result.token);
      }
      toast.success("Registration was completed successfully.", toastOptions);
      navigate("/invite/accept");
    } catch (error) {
      toast.error(
        "Something went wrong. Please try again later.",
        toastOptions
      );
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("invitation_token");
    if (token === null) {
      navigate("/login");
    } else {
      try {
        const decoded = jwtDecode(token);
        setEmail(decoded.email);
      } catch (error) {
        navigate("/login");
      }
    }
  }, [navigate]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>Appraisal Photo Report | Register</title>
      </Helmet>
      <RegistrationContainer>
        <RegistrationForm direction="column">
          <Typography
            component="h1"
            variant="h4"
            sx={{
              width: "100%",
              marginTop: "5vh",
              fontSize: "clamp(2rem, 10vw, 2.15rem)",
            }}
          >
            Register
          </Typography>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "90%",
              margin: "auto",
              gap: 2,
              paddingRight: "10px;",
              marginBottom: "2vh",
            }}
            onSubmit={handleSubmit}
          >
            <FormControl>
              <FormLabel htmlFor="email">Email {email}</FormLabel>
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="name">Name</FormLabel>
              <TextField
                id="name"
                name="name"
                variant="outlined"
                size="small"
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="password">Password</FormLabel>
              <TextField
                id="password"
                name="password"
                type="password"
                variant="outlined"
                required={true}
                onChange={(e) => setPassword(e.target.value)}
                size="small"
              />
            </FormControl>
            <Button type="submit" fullWidth variant="contained">
              Register
            </Button>
          </Box>
        </RegistrationForm>
      </RegistrationContainer>
    </HelmetProvider>
  );
};
