import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
});

/**
 * Login user with username and password and get access token in response
 * 
 * @param {string} email 
 * @param {string} password 
 * @returns Object
 */
export const login = async (email, password) => {
    const response = await api.post('/auth/login', {
        email: email,
        password: password
    });
    return response.data;
};

/**
 * Register user using invitation token and get access token in response
 * 
 * @param {string} name 
 * @param {string} password 
 * @returns Object
 */
export const register = async (name, password) => {
    const response = await api.post('/auth/register', {
        name: name,
        password: password,
        token: localStorage.getItem('invitation_token')
    });

    return response.data;
};

/**
 * Accept organization invitation
 * 
 * @returns Object
 */
export const acceptInvite = async () => {
    const response = await api.post('/organizations/invite/accept', {
        token: localStorage.getItem('invitation_token')
    }, {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });

    return response.data;
};
