import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const ImgWrapperCss = styled(Box)`
  position: relative;

  & > button {
    background-color: white;
    border-radius: 5px;
    opacity: 0;
    filter: drop-shadow(0 0 0.15rem grey);
    transition: visibility 0ms, opacity 500ms linear;
  }

  &:hover > button {
    visibility: visible;
    opacity: 1;
    background-color: white;
  }
`;

export const ImgCss = styled.img`
  width: 480px;
  height: auto;
  border: 0.1px solid grey;
`;

export const ImgNumberBoxCSS = styled(Box)`
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 50px;
  padding: 2px 5px;
  margin: 10px;

  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.9);
  filter: drop-shadow(0 0 0.15rem grey);

  font-size: 12px;
  color: black;
  font-weight: bold;
`;

export const FolderNotesWrapperCss = styled(Box)`
  width: 100%;
`;
