import { GridFolderImages } from "../GridFolderImages/GridFolderImages";

// styled-components
import { GridFolderItemListCss } from "./GridFolderItemList.styled";

export const GridFolderItemList = ({
  folderName,
  images,
  indexBasis,
  onUpdate,
}) => {
  return (
    <GridFolderItemListCss>
      <GridFolderImages
        folderName={folderName}
        images={images}
        indexBasis={indexBasis}
        onUpdate={onUpdate}
      />
    </GridFolderItemListCss>
  );
};
