import styled from "@emotion/styled";
import { IconButton } from "@mui/material";

// mui icons
import DeleteIcon from "@mui/icons-material/Delete";

// styled-components
export const IconButtonCss = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  margin: 8px;
  z-index: 1;
`;

export const DeleteIconCss = styled(DeleteIcon)``;
