// outer sources
import Grid from "@mui/material/Grid2";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { ConfirmProvider } from "material-ui-confirm";

// components
import { DeleteButtonDialog } from "../DeleteButtonDialog/DeleteButtonDialog";
import { EditTextAreaField } from "../EditTextAreaField/EditTextAreaField";

// utils
import { getDirectoryName } from "../../utils/getDirectoryName";
import { reorderArray } from "../../utils/reorderArray";

// styled-components
import {
  ImgWrapperCss,
  ImgCss,
  ImgNumberBoxCSS,
  FolderNotesWrapperCss,
} from "./GridFolderImages.styled";

export const GridFolderImages = ({
  folderName,
  images,
  indexBasis,
  onUpdate,
}) => {
  const handleDragEnd = ({ source, destination }, _provided) => {
    if (!destination) {
      return;
    }
    onUpdate(reorderArray(images, source.index, destination.index));
  };

  const generateImagesDOM = (path, preview, imgName, index) => {
    return (
      <Draggable key={path} draggableId={path} index={index}>
        {(draggableProvided) => (
          <Grid
            ref={draggableProvided.innerRef}
            size={"auto"}
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
          >
            <ImgWrapperCss>
              <ImgNumberBoxCSS>{`Фото ${
                indexBasis + index + 1
              }`}</ImgNumberBoxCSS>
              <ImgCss src={preview} alt={imgName} />

              <ConfirmProvider>
                <DeleteButtonDialog
                  images={images}
                  id={path}
                  onRemove={onUpdate}
                />
              </ConfirmProvider>
            </ImgWrapperCss>
          </Grid>
        )}
      </Draggable>
    );
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable">
        {(droppableProvided, snapshot) => (
          <Grid
            container
            spacing={1}
            columns={2}
            style={{
              backgroundColor: snapshot.isDraggingOver ? "#f7d0c3" : "white",
            }}
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
          >
            {droppableProvided.placeholder}

            {images.map(({ path, preview, name: imgName }, index) => {
              return folderName === getDirectoryName(path)
                ? generateImagesDOM(path, preview, imgName, index)
                : null;
            })}

            <FolderNotesWrapperCss>
              <EditTextAreaField
                defaultText={" "}
                style={{ width: "100%" }}
                addIcon={true}
              />
            </FolderNotesWrapperCss>
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  );
};
