import styled from "@emotion/styled";
import { List, ListItem } from "@mui/material";

export const ControlBoardListCss = styled(List)`
  display: flex;
  margin: 20px 0;
`;

export const ControlBoardListItemCss = styled(ListItem)`
  display: flex;
  justify-content: space-between;
`;
